@use 'src/styles/colors';
@use 'src/styles/vars';
@use 'src/styles/mixins';
@import '../../styles/env-variables';

.berg-small-popup {
  // position: absolute;
  // top: 0;
  position: fixed;
  top: 20%;
  right: 2%;
  z-index: 2;

  width: 300px;
  height: 65px;
  border-radius: 2px;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  p {
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    top: 2%;
    right: 3%;
  }
}

[data-client-style='brama'].berg-small-popup {
  background: $primary-color-brama;
}

[data-client-style='flisacy'].berg-small-popup {
  background: $primary-color-flisacy;
}

[data-client-style='polana'].berg-small-popup {
  background: $primary-color-polana;
}

[data-client-style='potok'].berg-small-popup {
  background: $primary-color-potok;
}
