@use 'src/styles/colors';

.berg-page-cookies-policy {
  text-align: left;
  padding: 1vmin 10vw 0 10vw;
}

.berg-page-cookies-policy h1 {
  font-size: 40px;
  font-weight: 700;
  color: #2d5638;
  margin-top: 3rem;
}

.berg-page-cookies-policy h2 {
  font-size: 24px;
  font-weight: 700;
  color: colors.$black;
  margin-top: 3rem;
}

.berg-page-cookies-policy p {
  font-size: 16px;
  color: colors.$black;
  margin-top: 0.5rem;
}
