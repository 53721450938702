@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../../styles/env-variables';

.berg-button {
  font-weight: 600;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0px;
  font-size: 0.875rem;
  vertical-align: middle;
  line-height: 1.75rem;
  padding: 6px 16px;
  height: 43px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  // border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  // color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    line-height: 1rem;
  }

  &__primary {
    background-color: colors.$primary-light;
    color: colors.$white;

    &:hover {
      background-color: colors.$primary-dark;
    }
  }

  &__primary-dark {
    background-color: colors.$primary-dark;
    color: colors.$white;

    &:hover {
      background-color: colors.$primary-light;
    }
  }

  &__secondary {
    background-color: colors.$white;
    color: colors.$primary-light;
    border: 2px solid colors.$primary-light;

    &:hover {
      color: colors.$primary-dark;
      border: 2px solid colors.$primary-dark;
    }
  }

  &__orange {
    background-color: colors.$orange-500;
    color: colors.$white;

    &:hover {
      background-color: colors.$dark-orange;
    }
  }

  &__gray-400 {
    background-color: colors.$gray-400;
    color: colors.$white;
  }

  &__white {
    background-color: colors.$white;
    color: colors.$primary-dark;
  }

  &__border {
    background-color: none;
    color: #2d5638;
    border: 2px solid #2d5638;
  }

  &__green {
    background-color: #2d5638;
    color: colors.$white;
    border: 2px solid #2d5638;
  }
}

[data-client-style='brama'].berg-button {
  &__full {
    color: white;
    background-color: $primary-color-brama;
    border: 2px solid $primary-color-brama;
  }

  &__border {
    color: $primary-color-brama;
    background-color: none;
    border: 2px solid $primary-color-brama;
  }
}

[data-client-style='flisacy'].berg-button {
  &__full {
    color: white;
    background-color: $primary-color-flisacy;
    border: 2px solid $primary-color-flisacy;
  }

  &__border {
    color: $primary-color-flisacy;
    background-color: none;
    border: 2px solid $primary-color-flisacy;
  }
}

[data-client-style='polana'].berg-button {
  &__full {
    color: white;
    background-color: $primary-color-polana;
    border: 2px solid $primary-color-polana;
  }

  &__border {
    color: $primary-color-polana;
    background-color: none;
    border: 2px solid $primary-color-polana;
  }
}

[data-client-style='potok'].berg-button {
  &__full {
    color: white;
    background-color: $primary-color-potok;
    border: 2px solid $primary-color-potok;
  }

  &__border {
    color: $primary-color-potok;
    background-color: none;
    border: 2px solid $primary-color-potok;
  }
}
