@use 'src/styles/colors';
@use 'src/styles/vars';
@use 'src/styles/mixins';

.berg-components-filter-bar {
  p {
    font-size: 20px;
    font-weight: 500;
    color: colors.$darker-grey;
  }

  &__dropdown .berg-components-dropdown__control {
    background-color: colors.$white;
    width: 271px;
    // margin: 0 30px;
    height: 43px;
  }

  &__dropdown .Dropdown-option.is-selected {
    background-color: #2d5638;
    color: colors.$white;
  }

  &__button {
    &--today {
      width: 95px;
      height: 43px;
      color: colors.$white;
      font-size: 14px;
      font-weight: 600;
      background-color: #2d5638;
      border-radius: 5px;
    }

    &--disabled {
      width: 95px;
      height: 43px;
      font-size: 14px;
      font-weight: 600;
      background-color: colors.$light-grey;
      color: colors.$darker-grey;
      border-radius: 5px;
    }

    &--tomorrow {
      width: 95px;
      height: 43px;
      color: colors.$white;
      font-size: 14px;
      font-weight: 600;
      background-color: #8db742;
      border-radius: 5px;
    }
  }

  &__period-wrapper {
    position: relative;
    z-index: 2;
  }

  &__period {
    position: relative;
    display: flex;
    align-items: center;
    height: 43px;
    padding: 0 43px 0 20px;
    background-color: colors.$white;
    border-radius: 8px;

    .berg-icons-calendar path {
      fill: colors.$light-grey;
    }
  }

  &__period-dates {
    margin: 0 40px;
    user-select: none;
  }

  &__period-arrow {
    border-radius: 0 8px 8px 0;
    @include mixins.dropdown-arrow;

    &::before {
      @include mixins.dropdown-arrow-closed;
    }
  }

  &__calendar {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;

    p {
      font-size: 14px;
    }
  }
}
