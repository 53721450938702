@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.berg-components-thanks {
  width: 632px;
  height: 99px;

  display: grid;
  grid-template-columns: 120px auto;
  align-items: center;
  border-radius: 8px;

  &__image {
    display: flex;
    place-self: center;
    max-width: 80px;
  }

  & p {
    color: colors.$white;
    text-align: start;
    font-size: 18px;
  }
}

[data-client-style='brama'].berg-components-thanks {
  background-color: $primary-color-brama;
}

[data-client-style='flisacy'].berg-components-thanks {
  background-color: $primary-color-flisacy;
}

[data-client-style='polana'].berg-components-thanks {
  background-color: $primary-color-polana;
}

[data-client-style='potok'].berg-components-thanks {
  background-color: $primary-color-potok;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-thanks {
    width: 100%;

    & p {
      color: colors.$white;
      text-align: start;
      font-size: 14px;
    }
  }
}
