@use 'src/styles/colors';
@use 'src/styles/vars';
@use 'src/styles/mixins';

.berg-components-room-full-card {
  margin-top: 47px;
  max-width: 1070px;

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    margin-top: 17px;
  }

  &__header {
    font-size: 1.25rem;
    font-weight: 600;
    color: colors.$gray-700;
    text-align: start;
    margin-bottom: 15px;
  }

  &__card {
    background-color: colors.$white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
  }

  &__images {
    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 50%;
    grid-template-areas:
      'big small1'
      'big small2';
    gap: 5px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    @include mixins.cursor-pointer;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: none;
    }

    img:first-child {
      grid-area: big;

      width: 100%;
      object-fit: contain;
    }

    img:nth-of-type(2) {
      grid-area: small1;

      width: 100%;
      height: 100%;
    }

    img:last-child {
      grid-area: small2;

      width: 100%;
      height: 100%;
    }
  }

  &__images-mobile {
    display: none;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: flex;
    }
  }

  &__features-and-price {
    display: flex;
    margin-left: 33px;
    align-items: center;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      justify-content: left;
      margin-left: 16px;
    }
  }

  &__features {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    margin: 15px;
    gap: 10px;
    width: 60%;

    div {
      display: grid;
      grid-template-columns: 10% 80% 10%;
      place-items: start;
      align-items: center;

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }

    hr {
      border: 1px solid colors.$gray-300;
      height: 160%;
      width: 0px;
    }

    div:nth-child(3) hr {
      display: none;
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: flex;
      flex-direction: column;
      hr {
        display: none;
      }
    }
  }

  &__submit {
    display: none;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: block;

      button {
        margin-top: 20px;
      }
    }
  }

  &__price {
    border: 1px solid colors.$gray-300;
    border-radius: 5px;
    width: 230px;
    margin: 15px;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: none;
    }
  }

  &__availability {
    grid-column: 1 / 3;
    color: colors.$orange-500;
    font-size: 12px;
    font-weight: 500;

    &::before {
      content: '';
      display: inline-block;
      margin-right: 8px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: colors.$orange-500;
    }
  }

  &__line {
    width: 94%;
    align-self: center;
  }

  &__description {
    display: grid;
    grid-template-columns: 65% 34%;
    padding: 0 35px;
    gap: 10px;

    &--text {
      text-align: start;

      strong {
        font-weight: 700;
        line-height: 4;
      }

      p {
        font-size: 0.875rem;
        color: colors.$gray-800;
      }

      li {
        font-size: 0.875rem;
        list-style-type: disc;
        list-style-position: inside;
      }

      p:first-child {
        font-size: 0.875rem;
        color: colors.$gray-800;
        strong {
          font-weight: 700;
          line-height: 2;
        }
      }
    }

    &--big {
      display: block;
      @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
        display: none;
      }
    }

    &--small {
      display: none;
      @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
        display: block;
        padding: 15px;
      }
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: flex;
      padding: 0 15px;
    }
  }

  &__stay {
    padding: 20px 35px;
    display: flex;
    flex-direction: row;

    &--price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center;
      }

      div {
        border: 1px solid colors.$gray-300;
        border-radius: 5px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 2px 15px;

        p:first-child {
          font-size: 0.875rem;
          font-weight: 600;
          color: colors.$gray-800;
          margin-left: 5px;
        }

        p {
          font-size: 0.875rem;
        }

        p:last-child {
          margin-right: 5px;
        }
      }
    }

    .berg-button {
      align-self: end;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        align-self: center;
      }
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: none;
    }

    &--small {
      display: none;
      @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
        display: block;
        padding: 0 15px;
      }
    }
  }

  &__devider {
    display: none;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: block;
    }
  }

  &__dialog {
    width: 100%;
    max-width: 120vmin;
  }

  &__gallery {
    .berg-components-arrows-carousel-container {
      margin: 0 120px;
    }

    .berg-components-arrows-carousel-container {
      .carousel-right-arrow-button,
      .carousel-left-arrow-button {
        background-color: colors.$white;
        border-radius: 5px;

        svg path {
          fill: colors.$grey;
        }
      }

      .carousel-right-arrow-button {
        right: -80px;
      }

      .carousel-left-arrow-button {
        left: -80px;
        background-color: colors.$white;
      }
    }

    &-close-btn {
      right: 130px;
    }
  }
}

.berg-components-room-price-summary {
  border: 1px solid colors.$gray-300;
  border-radius: 5px;
  max-height: 302px;
  color: colors.$gray-600;

  &__header {
    font-size: 14px;
    font-weight: 700;
    margin-top: 1rem;
  }

  &__price {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    padding: 20px 10px 0px 10px;

    > div {
      display: grid;
      grid-template-columns: 46% 60%;
      gap: 5px;
      align-self: center;
      align-items: center;
      font-weight: 500;

      label {
        font-size: 13px;
        font-weight: 500;
        // text-align: end;
        text-align: start;
        padding-left: 11px;
      }

      > p {
        font-size: 24px;
        font-weight: 600;
        margin-left: -21px;
        white-space: nowrap;
        span {
          font-size: 18px;
          font-weight: 400;
        }
      }
    }

    &--supply {
      display: flex !important;
      color: colors.$primary-dark;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 13px;
      margin-top: -17px;
    }
  }

  &__supply {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: start;
    padding: 20px 10px 0px 20px;
    font-size: 14px;
    font-weight: 500;

    div:last-child {
      font-size: 11.5px;
      font-weight: 500;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }
  }

  &__total-price {
    display: flex;
    place-content: space-around;
    font-size: 14px;
    font-weight: 700;
    padding: 15px 0;
  }

  &__bed-layout {
    display: flex;
    flex-direction: column;
    text-align: start;
    padding: 20px 10px 0px 20px;
    height: 148px;

    p {
      font-size: 14px;
      font-weight: 500;
    }

    div {
      display: grid;

      > div {
        display: grid;
        grid-template-columns: 80% 20%;
        gap: 5px;

        p {
          font-size: 11.5px;
          font-weight: 400;
          white-space: nowrap;
        }
      }
    }
  }

  .berg-button__primary-dark {
    width: 87%;
    margin-bottom: 21px;
  }

  .berg-devider:nth-child(6) {
    width: 90%;
  }
}
