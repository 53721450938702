@use 'src/styles/colors';

.berg-components-number-counter {
  display: grid;
  grid-template-columns: 1fr 24px;
  width: 67px;
  height: 43px;
  background-color: colors.$off-white;
  border-radius: 5px;

  &[aria-disabled='true'] {
    opacity: 0.6;
    pointer-events: none;
  }
}

.berg-components-number-counter-digit {
  grid-row: 1 / 3;
  place-self: center;
  font-size: 14px;
}

.berg-components-number-counter-chevron {
  line-height: 10px;
  text-align: center;
  grid-column: 2;
  color: colors.$grey;
  background-color: colors.$lighter-grey;
  border-radius: 0 0 5px 0;
}

.berg-components-number-counter-chevron:first-of-type {
  border-bottom: 1px solid colors.$light-grey;
  border-radius: 0 5px 0 0;
}
