@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-contact-form {
  position: relative;
  min-height: 650px;
  background-color: colors.$primary-light;
  color: colors.$white;
  text-align: initial;
}

.contact-form-content {
  position: absolute;
  top: 40px;
  left: 15%;
}

.contact-form-content h6 {
  font-size: 25px;
}

.contact-form-input {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 30px;
}

.contact-form-submit-btn {
  background-color: colors.$white;
  padding: 11px 48px;
  height: 48px;
  margin-top: 30px;
  color: colors.$primary-dark;
  transition: background-color 0.15s;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
}

.contact-form-submit-btn:hover {
  background-color: colors.$lightest-grey;
}

.contact-icons-wrapper {
  margin-top: 21px;
}

.contact-icons-wrapper .berg-icon-ig {
  margin-right: 12px;
}

.contact-form-input textarea {
  resize: vertical;
  max-height: 170px;
  margin-top: 7px;
  border: 1px colors.$white solid;
  padding: 7px;
  min-height: 90px;
}

.contact-form-input input,
.contact-form-input textarea {
  background: none;
  border-bottom: 1px solid colors.$white;
  height: 36px;
  width: 270px;
}

.contact-form-input input::placeholder,
.contact-form-input textarea::placeholder {
  color: colors.$white;
  font-size: 12px;
  opacity: 0.6;
}

.contact-form-input input::-ms-input-placeholder,
.contact-form-input textarea::-ms-input-placeholder {
  color: colors.$white;
  opacity: 1;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-contact-form {
    flex: 1 0 50%;
  }
}
