@use 'src/styles/mixins';
@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-pages-start {
  &__sub-header-placeholder {
    width: 100%;
    height: 147px;
    background-color: #235789;
    display: flex;
    align-items: center;
    justify-content: center;

    & h2 {
      font-size: 30px;
      font-weight: 500;
      color: colors.$white;
    }
  }

  &__header {
    width: 100%;
    height: 140px;
    background-color: colors.$white;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 30px 50px;
    display: flex;
    justify-content: space-between;

    & h2 {
      font-size: 30px;
      font-weight: 500;
      color: #181818;
    }

    & img {
      height: 103px;
    }

    &--line {
      border-left: 1px solid #181818;
      height: 50px;
      width: 0;
      margin: 0 30px;
    }

    p {
      color: #181818;
      font-size: 24px;
      font-weight: 600;
    }

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .right {
      gap: 77px;
    }

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      height: 87px;
      padding: 0 10px;

      & img {
        height: 41px;
      }

      p {
        font-size: 14px;
      }

      &--line {
        height: 30px;
        margin: 0 15px;
      }

      .right {
        gap: 14px;
      }
    }
  }
}

.berg-components-billboard {
  &__image {
    max-width: 90vw;
    max-height: 80vh;
  }

  &__close-btn {
    top: 0;
    right: 0;
    padding: 8px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
