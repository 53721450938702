@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';

.berg-components-school {
  &-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 284px;
    background-color: colors.$white;
    margin-bottom: 24px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      flex-direction: row;
    }
  }

  &__picture {
    overflow: hidden;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      width: 34%;
    }
  }

  &__line {
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  &__content {
    flex: 1;

    display: grid;
    padding: 17px 0;
    grid-template-rows: auto 1fr 1fr auto auto;
    grid-template-areas:
      'title'
      'choices'
      'sum'
      'info'
      'submit';

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      padding: 26px 26px 26px 40px;
      grid-template-rows: auto 1fr 1fr auto;
      grid-template-areas:
        'title title'
        'choices sum'
        'info submit';
    }
  }

  &__title {
    grid-area: title;

    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;

    hr {
      margin: 17px 0;
      @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
        margin: 0;
        text-align: start;
      }
    }
  }

  &__choices {
    grid-area: choices;
    align-self: center;
    display: flex;
    align-items: center;
    z-index: 1;
    gap: 20px;
    margin: 0 auto;

    .berg-components-dropdown-wrapper {
      width: 190px;
    }

    .Dropdown-option.is-selected {
      color: colors.$white;
      background-color: colors.$primary-dark;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      margin: 0;
    }
  }

  &__choice-label-dropdown {
    align-self: center;
    margin-right: 20px;
  }

  &__sum {
    grid-area: sum;
    margin: 20px 34px 10px 0;
    justify-self: center;
    align-self: end;
    font-size: 24px;
    font-weight: 600;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
      margin: 0;
    }
  }

  &__submit {
    grid-area: submit;
    justify-self: center;
    background-color: colors.$primary-dark;
    color: colors.$white;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 30px;
    line-height: 1;
    white-space: nowrap;
    height: 38px;
    align-self: center;
    margin: 0 34px 26px 0;

    &:disabled {
      background-color: colors.$light-grey;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
    }
  }

  &__info {
    grid-area: info;
    display: none;

    p {
      font-size: 12px;
      color: colors.$grey;
      margin: 0 15px;
      text-align: start;
      line-height: 20px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      display: flex;
      justify-self: start;
      align-items: center;
    }
  }
}
