@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-dialog-cart-popups {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 20px 15px 20px;
  border-radius: 15px;
  background: colors.$white;
  z-index: 9999;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    width: 93%;
  }

  &__content {
    max-height: 90vh;
  }

  &__info {
    h3 {
      padding-top: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: colors.$dark-grey;
      align-self: start;
      @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
        padding-top: 20px;
        font-size: 24px;
      }
    }

    img {
      width: 40%;
      padding: 19px;
    }
    ul {
      padding: 15px 40px 29px;
      max-width: 400px;
      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        padding: 15px 0;
      }

      li {
        text-align: center;
        color: colors.$gray-600;
        list-style: none;
        p {
          display: inline;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          span {
            font-weight: 600;
          }

          p:nth-child(3) {
            font-weight: 500;
            text-align: center;
            display: flex;
            padding-top: 10px;
          }

          p:last-child {
            font-weight: 500;
            text-align: center;
            display: flex;
            font-size: 12px;
          }
        }
        @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
          text-align: left;
          p {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }

  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 99999;
    line-height: 0;

    img {
      transition: transform 0.5s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 20px 0 8px 0;

    button {
      padding: 10px 30px;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      transition: all 0.3s;
      border-radius: 8px;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        padding: 10px 20px;
      }

      &.buttons-accommodation {
        margin-top: -15px;
        @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
          margin-top: 0px;
        }
      }
    }

    & .berg-devider__lighter-grey {
      width: 158%;
      margin-left: -56px;
    }

    &-btn-one {
      border: 1px solid #2d5638;
      color: #2d5638;
      &:hover {
        border-color: #2d5638;
        color: #2d5638;
      }
    }

    &-btn-two {
      background: #2d5638;
      color: colors.$white;
      &:hover {
        background: #2d5638;
        color: colors.$white;
      }
    }
  }
}
