@use 'src/styles/colors';
@use 'src/styles/vars';

// .footer-component-navigation {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
// }

// .footer-component-navigation .berg-components-menu-item {
//   margin: 5px 10px 5px 0;
// }

// .footer-component-navigation .berg-components-navigation-dropdown-list {
//   top: 27px;
// }

// .footer-component-navigation .berg-components-navigation-dropdown-list {
//   background: colors.$white;
//   color: colors.$black;
// }

.footer-component-body {
  display: flex;
  align-items: center;
  padding-top: 25px;
  justify-content: space-between;
  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    flex-direction: column;
  }

  hr {
    display: block;
    border-left: 1px solid #616161;
    align-self: center;
    width: 0;
    height: 75px;
    margin: 0 25px;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      width: 100%;
      height: 1px;
      border: 1px solid #616161;
      margin: 10px 0;
    }
  }

  &__left {
    display: flex;
    flex-direction: row;
    gap: 48px;
    align-items: start;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      flex-direction: column;
      gap: 10px;
      align-items: center;
    }
  }
}

footer hr {
  display: block;
  width: 100%;
  border: 1px solid #616161;
  margin: 15px 0;
}

.footer-component-company-data {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;

  p {
    color: #616161;
    font-size: 14px;
    font-weight: 500;
  }

  div p {
    line-height: 1.5rem;
  }

  a {
    text-decoration: none;
  }
}

.footer-component-button button {
  display: inline-flex;
  padding: 6px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #616161;
  color: #616161;
  background-color: colors.$white;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
  text-decoration: none;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    margin-top: 15px;
  }
}

.footer-component-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;

  &__rules {
    display: flex;

    & div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    a {
      width: 30%;
      margin-bottom: 10px;
      text-wrap: nowrap;
    }
  }

  &__berg {
    display: flex;
    align-self: start;

    svg path {
      fill: #616161;
    }
  }
}

.footer-component-header img {
  height: 103px;
}

.footer-component-line {
  border-left: 1px solid colors.$green;
  height: 64px;
  width: 0;
  margin: 0 30px;

  &__rules {
    border-left: 1px solid colors.$green;
    width: 0;
    margin: 0 30px;
  }
}

.footer-component-footer a {
  text-decoration: none;
}

.footer-component-footer-copyright {
  margin-left: auto;
  margin-right: 10px;
  align-self: center;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .footer-component-footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    justify-content: center;
    gap: 5px;

    &__rules {
      gap: 10px;
      width: 100%;
      justify-content: center;
    }

    div {
      display: flex;
      text-align: start;
      flex-direction: column;
      text-wrap: nowrap;
    }
  }

  .footer-component-footer__berg {
    flex-direction: row !important;

    .footer-component-footer-copyright {
      justify-content: center;
      align-self: center;
    }
  }

  .footer-component-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &__partners {
      padding-top: 12px;
    }
  }

  .footer-component-company-data {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;

    button {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) and (min-width: map-get(vars.$breakpoints, 'small')) {
  .footer-component-footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: center;
    gap: 15px;

    &__rules {
      gap: 10px;
      width: 100%;
      justify-content: center;
    }

    & div {
      display: flex;
      justify-content: center;
    }
  }
}
