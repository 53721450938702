@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';

.berg-components-ticket {
  @include mixins.ticket-wrapper;
}

.berg-components-ticket-picture.highlighted-ticket {
  border: 12px solid colors.$primary-dark;
}

.berg-components-ticket-picture {
  @include mixins.ticket-picture;
}

.berg-components-ticket-content {
  @include mixins.ticket-content;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-template-areas:
      'title title'
      'range range'
      'dropdown dropdown'
      'choices choices'
      'sum sum'
      'footnotes submit';
  }
}

.berg-components-ticket-title {
  grid-area: title;

  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  text-align: start;
}

.berg-components-ticket-range {
  grid-area: range;

  font-size: 12px;
  font-weight: 500;
  color: colors.$primary-dark;
  text-align: center;
}

.berg-components-ticket-types {
  grid-area: choices;
  align-self: center;

  display: grid;
  justify-items: end;
  grid-template-columns: auto;
  max-width: 300px;
  column-gap: 10px;
  row-gap: 40px;
  justify-content: space-around;
  margin: 20px 0;
  z-index: 1;

  @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-columns: auto auto;
    max-width: unset;
    border-bottom: unset;
    margin: unset;
  }
}

.berg-components-ticket-type {
  display: grid;
  grid-template-columns: auto auto;
  height: fit-content;
  column-gap: 22px;
  font-weight: 500;
  font-size: 14px;
}

.ticket-choice-dropdown {
  grid-area: dropdown;
  display: grid;
  grid-template-columns: auto 200px;
  justify-self: start;
  column-gap: 22px;
  height: fit-content;
  margin: 20px 0;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
}

.berg-components-ticket-type:not(.ticket-choice-dropdown) > div:nth-child(1) {
  justify-self: end;
  white-space: nowrap;
}

.berg-components-ticket-type > div:nth-child(2) {
  justify-self: end;
  font-size: 14px;
  font-weight: 600;
  color: colors.$primary-dark;
  grid-column: 1;
}

.berg-components-ticket-type > div:nth-child(3) {
  grid-row: 1 / 3;
  grid-column: 2;
}

.berg-components-ticket-sum {
  grid-area: sum;
  font-size: 24px;
  font-weight: 600;
  text-align: end;

  &__notes {
    font-size: 14px;
    font-weight: 400;
    color: colors.$grey;

    &[aria-hidden='true'] {
      visibility: hidden;
    }
  }
}

.berg-components-ticket-footnotes {
  grid-area: footnotes;

  display: grid;
  justify-content: start;
  align-items: center;
  grid-template-rows: auto auto;
  font-size: 12px;
  margin-bottom: 37px;

  &[aria-disabled='true'] {
    opacity: 0.6;
  }

  div {
    grid-column: 1;
    color: colors.$grey;
    text-align: start;
  }

  button {
    grid-column: 1;
    color: colors.$primary-dark;
    font-weight: 700;
  }

  img {
    grid-column: 2;
    height: 1.2em;
    margin-left: 0.25rem;
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(2em);
    content: '';
    border-bottom: 1px solid colors.$lighter-grey;
  }
}
.berg-components-ticket-submit,
.berg-components-ticket-buy {
  color: colors.$white;
  font-size: 15px;
  font-weight: 700;
  padding: 10px 30px;
  margin-left: 10px;
  margin-top: 30px;
  line-height: 1;
  white-space: nowrap;
  border-radius: 8px;
}

.berg-components-ticket-submit {
  background-color: colors.$primary-dark;
}

.berg-components-ticket-buy {
  background-color: colors.$orange-500;
}

.berg-components-ticket-buttons {
  grid-area: submit;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

@media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
  .berg-components-ticket-content {
    display: grid;
    padding: 26px 26px 26px 40px;
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-template-areas:
      'title title'
      'range range'
      'dropdown dropdown'
      'choices choices'
      'sum sum'
      'footnotes submit';
    width: 60%;
  }

  .berg-components-ticket-types {
    justify-items: start;
    grid-template-columns: auto auto;
    display: flex;
    column-gap: unset;
    row-gap: unset;
  }

  .ticket-choice-dropdown {
    grid-column: 1 / 3;
  }

  .berg-components-ticket.highlighted-ticket {
    outline: 12px solid colors.$primary-dark;
    margin-bottom: 36px;
  }

  .berg-components-ticket-today {
    margin-bottom: 16px;
    text-align: start;
  }

  .berg-components-ticket-submit {
    margin-top: unset;
  }

  .berg-components-ticket-footnotes {
    margin-bottom: unset;
  }

  .berg-components-ticket-footnotes::after {
    display: none;
  }

  .berg-components-ticket-range {
    text-align: start;
  }
}
