@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../../styles/env-variables';

.order-summary-header {
  width: 100%;
  margin-top: 3rem;

  &__item-header {
    font-size: 1.125rem;
    font-weight: 500;
    text-align: start;

    &--price {
      font-size: 1rem;
    }

    &--date {
      font-size: 1.125rem;
      font-weight: 600;
      text-align: start;
    }
  }

  &__item-description {
    &--date {
      font-size: 1.125rem;
      font-weight: 700;
      margin-left: 5px;
    }

    &--price {
      font-size: 1rem;
      margin-left: 5px;
    }

    &--bold {
      font-size: 1.125rem;
      margin-left: 5px;
      font-weight: 700;
    }

    &--price-bold {
      font-size: 1rem;
      margin-left: 5px;
      font-weight: 700;
    }
  }
}

[data-client-style='brama'].order-summary-header__item-description--date {
  color: $primary-color-brama;
}

[data-client-style='flisacy'].order-summary-header__item-description--date {
  color: $primary-color-flisacy;
}

[data-client-style='polana'].order-summary-header__item-description--date {
  color: $primary-color-polana;
}

[data-client-style='potok'].order-summary-header__item-description--date {
  color: $primary-color-potok;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .order-summary-header {
    &__item-header--date {
      font-size: 1.06rem;
      font-weight: 500;
      text-align: start;
    }

    &__item-description--date {
      font-size: 1.02rem;
      font-weight: 500;
      color: #3b6145;
      margin-left: 5px;
    }
  }
}
