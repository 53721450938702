@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.payment-status-wrapper {
  &__payment-status {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__item-header {
    font-weight: 600;
    font-size: 1.25rem;
  }

  &__payment-box {
    background-color: #f7f7f7;
    width: 100%;
    padding-left: 1rem;
  }
  &__buttons {
    width: 100%;
  }
  &__thank-you-message {
    font-weight: 600;
    font-size: 1.125rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-columns: auto;
  }
}

[data-client-style='brama'].payment-status-wrapper__payment-status {
  color: $primary-color-brama;
}

[data-client-style='flisacy'].payment-status-wrapper__payment-status {
  color: $primary-color-flisacy;
}

[data-client-style='polana'].payment-status-wrapper__payment-status {
  color: $primary-color-polana;
}

[data-client-style='potok'].payment-status-wrapper__payment-status {
  color: $primary-color-potok;
}

.payment-status-label {
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 3.5rem;
  text-align: start;
}

[data-client-style='brama'].payment-status-label {
  color: $primary-color-brama;
}

[data-client-style='flisacy'].payment-status-label {
  color: $primary-color-flisacy;
}

[data-client-style='polana'].payment-status-label {
  color: $primary-color-polana;
}

[data-client-style='potok'].payment-status-label {
  color: $primary-color-potok;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .payment-status-wrapper__buttons {
    display: flex;
    flex-direction: column-reverse;

    button {
      align-self: center;
    }

    div {
      align-self: center;
    }
  }
}

.accommodations-table {
  width: 100%;
  table.berg-components-order-table td:nth-child(2) {
    position: absolute;
    top: 4px;
    right: 61%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 4px;
    right: 51%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 4.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    top: 4px;
    right: 42%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 4px;
    right: 32.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    top: 0px;
    right: 18%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 4%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(7) {
    position: absolute;
    top: 4px;
    right: 11%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(8) {
    position: absolute;
    top: 4px;
    right: 1%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 2.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(9) {
    position: absolute;
    top: 4px;
    right: 0;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 5px;
      left: -0.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  .max-width-th {
    max-width: 75px;
  }

  .width-date {
    width: 13%;
    text-align: center;
  }

  .sum {
    text-align: center;
  }

  table.berg-components-order-table td:first-of-type {
    padding: 20px 10px 10px 20px;
  }
}

.parking-table-status {
  width: 100%;
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    // top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    // top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    // top: 25px;
    right: 14%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    // top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}

.therms-table-status {
  width: 100%;
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    // top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    // top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    // top: 25px;
    right: 14.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    // top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      // top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}

.skiPasses-table-status {
  width: 100%;
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 25px;
    right: 14.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}
