@use 'src/styles/colors';

.berg-components-single-calendar {
  width: 280px;
  overflow: hidden;
  background-color: colors.$white;

  &__nav {
    margin: 0 -15px;
    padding: 0 15px;
    background-color: colors.$white;
    color: colors.$black;
    height: 50px;
    position: relative;

    button {
      position: absolute;
      cursor: pointer;
      left: 10px;
      font-size: 32px;
      line-height: 1;
      top: 16px;
      width: 30px;
      text-align: center;
      display: inline-block;
      color: colors.$black-alpha-60;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: none;
      &:hover {
        color: colors.$black-alpha-20;
      }
      &:last-child {
        left: auto;
        right: 10px;
      }
    }

    span {
      margin: 0;
      position: absolute;
      left: 40px;
      right: 40px;
      text-align: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 20px;
      line-height: 67px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      text-transform: capitalize;
    }
  }

  .single-calendar-days {
    font-size: 0;

    span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      text-align: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      position: relative;
      font-size: 16px;

      &.label {
        font-size: 16px;
        cursor: initial;
        color: colors.$grey;
      }
    }

    &__bg-layer {
      position: absolute;
      inset: 0;

      span {
        font-size: 0;

        &.between {
          &--first {
            background-color: colors.$first-calendar-range-bg;
          }

          &--second {
            background-color: colors.$second-calendar-range-bg;
          }
        }
      }
    }

    &__numbers-layer span {
      &:not(.label):not(.active):not(.disabled) {
        cursor: pointer;

        &:hover {
          background-color: colors.$light-grey;
          border-radius: 50%;
        }
      }

      &.current {
        font-weight: 700;
      }

      &.active {
        color: colors.$white;
        background-color: colors.$primary-light;
        border-radius: 50%;
      }

      
      &.disabled {
        color: colors.$grey;
      }
    }

    &__days {
      position: relative;
    }
  }
}
