@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.berg-components-language-dropdown {
  position: relative;
  color: colors.$white;
  border-radius: 8px;
}

[data-client-style='brama'].berg-components-language-dropdown {
  background: $primary-color-brama;
}

[data-client-style='flisacy'].berg-components-language-dropdown {
  background: $primary-color-flisacy;
}

[data-client-style='polana'].berg-components-language-dropdown {
  background: $primary-color-polana;
}

[data-client-style='potok'].berg-components-language-dropdown {
  background: $primary-color-potok;
}

.berg-components-language-dropdown button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  padding: 0 6px 0 12px;
  width: 59px;
  height: 51px;
}

.berg-components-language-dropdown button svg {
  pointer-events: none;
}

.berg-components-language-dropdown-list {
  display: none;
  position: absolute;
  left: 0;
  top: 54px;
  color: colors.$black;
  z-index: 999;
  border-radius: 8px;
}

[data-client-style='brama'].berg-components-language-dropdown-list {
  background: $primary-light-color-brama;
}

[data-client-style='flisacy'].berg-components-language-dropdown-list {
  background: $primary-light-color-flisacy;
}

[data-client-style='polana'].berg-components-language-dropdown-list {
  background: $primary-light-color-polana;
}

[data-client-style='potok'].berg-components-language-dropdown-list {
  background: $primary-light-color-potok;
}

.berg-components-language-dropdown-list-item {
  padding: 18px;
  cursor: pointer;
  line-height: 0;
}

[data-client-style='brama'].berg-components-language-dropdown-list-item:hover {
  background: $primary-color-brama;
}

[data-client-style='flisacy'].berg-components-language-dropdown-list-item:hover {
  background: $primary-color-flisacy;
}

[data-client-style='polana'].berg-components-language-dropdown-list-item:hover {
  background: $primary-color-polana;
}

[data-client-style='potok'].berg-components-language-dropdown-list-item:hover {
  background: $primary-color-potok;
}

.berg-components-language-dropdown-list-item:not(:last-child) {
  border-bottom: 1px solid colors.$white;
}

[data-client-style='brama'].berg-components-language-dropdown-list-item.selected {
  background: $primary-color-brama;
}

[data-client-style='flisacy'].berg-components-language-dropdown-list-item.selected {
  background: $primary-color-flisacy;
}

[data-client-style='polana'].berg-components-language-dropdown-list-item.selected {
  background: $primary-color-polana;
}

[data-client-style='potok'].berg-components-language-dropdown-list-item.selected {
  background: $primary-color-potok;
}

.berg-components-language-dropdown-list li a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  text-decoration: none;
  white-space: nowrap;
}

.berg-components-language-dropdown-list li a:hover {
  background: colors.$black-alpha-20;
}

.berg-components-language-dropdown-list.visible {
  display: block;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    display: flex;
    flex-direction: column;
  }
}
