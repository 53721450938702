@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-basket {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: colors.$white;
  border-right: 1px solid colors.$black;
  font-family: 'Poppins';

  &__list-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    width: 100vw;
    padding: 0 20px 10px 20px;
    border-radius: 5px;
    background: colors.$white;
    color: colors.$black;
    font-size: 14px;
    line-height: 21px;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'robert')) {
    display: flex;
    position: relative;

    &__list-wrapper {
      left: -426%;
      width: 351px;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 17px 0 20px;
    background: none;
    cursor: pointer;
  }

  &__contents-wrapper {
    position: relative;
  }

  &__contents {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 2px;
    border-radius: 50%;
    font-size: 12px;
    background: colors.$darker-grey;
    color: colors.$white;
    margin-top: 1px;
    padding-left: 3px;
  }

  &__list-header {
    justify-content: start;
    border-bottom: 1px solid colors.$medium-grey;
    font-weight: 600;
  }

  &__list-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    bottom: 0px;
    left: 50%;
    height: auto;
    margin-top: 20px;
    padding: 10px 30px;
    transform: translateX(-50%);
    background: colors.$orange-500;
    color: colors.$white;
    border-radius: 8px;
  }

  .basket-list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    min-height: 10px;
    font-weight: 500;
    border-bottom: 1px solid colors.$medium-grey;

    &__name {
      display: block;
      width: 200px;
      text-align: left;
      white-space: normal;
    }

    &:not(:last-child) .basket-list-item__name {
      color: colors.$dark-orange;
    }
  }
  .berg-components-basket-item-text-grey {
    color: colors.$grey;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'xl')) {
  .berg-components-basket__contents {
    padding-left: 2px;
  }
}
