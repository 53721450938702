@use 'src/styles/mixins';
@use 'src/styles/colors';

.berg-components-dropdown {
  &-wrapper {
    position: relative;

    input {
      display: none;
    }

    &--disabled {
      opacity: 0.6;
    }
  }

  &__control {
    background-color: colors.$off-white;
    border: initial;
    border-radius: initial;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;

    @include mixins.cursor-pointer;
  }

  &__arrow-open {
    @include mixins.dropdown-arrow;

    &::before {
      position: absolute;
      top: 43%;
      left: 42%;
      transform: rotate(45deg);
      border-top: 2px solid colors.$darker-grey;
      border-left: 2px solid colors.$darker-grey;
      content: '';
      width: 8px;
      height: 8px;
    }
  }

  &__arrow-closed {
    @include mixins.dropdown-arrow;

    &::before {
      @include mixins.dropdown-arrow-closed;
    }
  }

  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &--with-icon {
      margin-left: 34px;
    }
  }

  &__placeholder-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  .Dropdown-menu {
    border-radius: 5px;
  }
}
