@use 'src/styles/colors';

.berg-components-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-height: 90vh;

  &__content {
    max-height: 90vh;
  }

  &-backdrop {
    background-color: colors.$black-alpha-60;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9998;
  }

  &__close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1;
    line-height: 0;

    img {
      transition: transform 0.5s;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
