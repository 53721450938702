@use 'src/styles/mixins';
@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.berg-pages-activities {
  padding: 10px 0;
  width: 100%;

  @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
    padding: 30px 0;
  }

  &__attractions {
    padding: 20px 0;
    z-index: 1;
    position: relative;
    z-index: 1;

    svg {
      width: 32px;
      height: 32px;
    }

    & > hr {
      height: 1px;
      border: 1px solid colors.$light-grey;
      margin: 20px 0;
      display: none;
    }

    // .berg-components-filter-bar {
    //   p:last-of-type {
    //     display: none;
    //   }
    // }

    .berg-components-tooltip {
      display: none;
    }

    .berg-components-filter-bar {
      justify-content: start !important;
    }

    .activities-calendar {
      $legend-colors: 1 colors.$first-calendar-range-bg,
        2 colors.$second-calendar-range-bg, 3 colors.$primary-light;

      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      padding: 12px;
      border-radius: 8px;

      &__middle-wrapper {
        border-radius: 8px;
        background-color: colors.$white;
        padding: 0 16px 16px 16px;
      }

      &__legend {
        font-family: 'Roboto';
        font-size: 12px;
        color: colors.$grey;
        text-align: start;

        @each $index, $color in $legend-colors {
          div:nth-child(#{$index}) {
            display: flex;
            align-items: center;
            height: 26px;
            line-height: 1em;

            span {
              display: inline-block;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background-color: $color;
              margin: 0 0.5em;
            }
          }
        }
      }
    }

    .berg-components-single-calendar
      .single-calendar-days__numbers-layer
      span.active {
      color: colors.$white;
      border-radius: 50%;
    }

    [data-client-style='brama'].activities-calendar,
    [data-client-style='brama'].berg-components-single-calendar
      .single-calendar-days__numbers-layer
      span.active {
      background-color: $primary-color-brama;
    }

    [data-client-style='flisacy'].activities-calendar,
    [data-client-style='flisacy'].berg-components-single-calendar
      .single-calendar-days__numbers-layer
      span.active {
      background-color: $primary-color-flisacy;
    }

    [data-client-style='potok'].activities-calendar,
    [data-client-style='potok'].berg-components-single-calendar
      .single-calendar-days__numbers-layer
      span.active {
      background-color: $primary-color-potok;
    }

    [data-client-style='polana'].activities-calendar,
    [data-client-style='polana'].berg-components-single-calendar
      .single-calendar-days__numbers-layer
      span.active {
      background-color: $primary-color-polana;
    }

    &__parking {
      &--info {
        text-align: start;
        p {
          color: var(--dark-gray);
          font-size: 14px;
          font-weight: 500;
        }
      }
      &--aquapark {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 10px;
        color: colors.$primary-dark;
        text-align: start;
        p {
          color: var(--dark-gray);
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .berg-pages-activities-paragraph {
    padding: 1rem;
    text-align: left;
    font-weight: 500;
    font-family: Poppins;
    font-size: 14px;
    span {
      color: colors.$primary-dark;
      font-weight: 700;
    }
  }

  .berg-pages-activities-rental-header {
    color: colors.$primary-dark;
  }

  .berg-pages-activities-button {
    text-decoration: none;
    display: flex;
    align-self: self-end;
    margin-top: 30px;

    &__img {
      width: 112px;
      height: 93px;
      background-color: colors.$light-very-perry;
      border-radius: 10px 0 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__text {
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 344px;
      height: 93px;
      background-color: colors.$white;
      border-radius: 0 10px 10px 0;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: start;
    margin: 0 20px 20px 24px;
    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      font-size: 20px;
      font-weight: 600;
      text-align: start;
      margin: 0 0 20px 95px;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: stretch;
    padding: 0 20px;
    margin-right: -13px;
    margin-bottom: -13px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
      display: flex;
      justify-content: space-between;
      margin-right: -23px;
      margin-bottom: -23px;

      & > * {
        margin-right: 23px;
        margin-bottom: 23px;
      }
    }

    & > * {
      margin-right: 30px;
      margin-bottom: 13px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    & > p {
      font-size: 14px;
      font-weight: 500;
      text-align: start;
      padding: 10px 40px;

      & > a {
        text-decoration: none;
        color: colors.$primary-dark;
        font-weight: 700;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      margin-top: 45px;
    }
  }

  &__back-buttons {
    position: absolute;
    left: 90px;
    margin: 10px 0;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      display: none;
    }
  }

  &__back-mobile-buttons {
    display: none;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      z-index: 1;
      position: absolute;
      left: 34px;
      top: 559px;
      display: flex;
    }
  }

  &__rooms-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    margin-top: 20px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      gap: 75px;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__combining-promo {
    font-size: 13px !important;
    padding: 0 !important;
  }

  //   .activities-calendar {
  //     $legend-colors: 1 colors.$first-calendar-range-bg,
  //       2 colors.$second-calendar-range-bg, 3 colors.$primary-light;

  //     display: flex;
  //     flex-direction: column;
  //     position: relative;
  //     z-index: 1;
  //     padding: 12px;
  //     border-radius: 8px;
  //     background-color: #8db742;

  //     &__middle-wrapper {
  //       border-radius: 8px;
  //       background-color: colors.$white;
  //       padding: 0 16px 16px 16px;
  //     }

  //     &__legend {
  //       font-family: 'Roboto';
  //       font-size: 12px;
  //       color: colors.$grey;
  //       text-align: start;

  //       @each $index, $color in $legend-colors {
  //         div:nth-child(#{$index}) {
  //           display: flex;
  //           align-items: center;
  //           height: 26px;
  //           line-height: 1em;

  //           span {
  //             display: inline-block;
  //             width: 16px;
  //             height: 16px;
  //             border-radius: 50%;
  //             background-color: $color;
  //             margin: 0 0.5em;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .berg-components-single-calendar
  //     .single-calendar-days__numbers-layer
  //     span.active {
  //     color: colors.$white;
  //     background-color: #8db742;
  //     border-radius: 50%;
  //   }

  //   &__parking {
  //     &--info {
  //       text-align: start;
  //       p {
  //         color: var(--dark-gray);
  //         font-size: 14px;
  //         font-weight: 500;
  //       }
  //     }
  //     &--aquapark {
  //       display: flex;
  //       justify-content: start;
  //       align-items: center;
  //       gap: 10px;
  //       color: colors.$primary-dark;
  //       text-align: start;
  //       p {
  //         color: var(--dark-gray);
  //         font-size: 14px;
  //         font-weight: 500;
  //       }
  //     }
  //   }
  // }

  // .berg-pages-activities-paragraph {
  //   padding: 1rem;
  //   text-align: left;
  //   font-weight: 500;
  //   font-family: Poppins;
  //   font-size: 14px;
  //   span {
  //     color: colors.$primary-dark;
  //     font-weight: 700;
  //   }
  // }

  // .berg-pages-activities-rental-header {
  //   color: colors.$primary-dark;
  // }

  // .berg-pages-activities-button {
  //   text-decoration: none;
  //   display: flex;
  //   align-self: self-end;
  //   margin-top: 30px;

  //   &__img {
  //     width: 112px;
  //     height: 93px;
  //     background-color: colors.$light-very-perry;
  //     border-radius: 10px 0 0 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //   }

  //   &__text {
  //     font-size: 14px;
  //     font-weight: 500;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     width: 344px;
  //     height: 93px;
  //     background-color: colors.$white;
  //     border-radius: 0 10px 10px 0;
  //   }
}

.berg-information-image-map-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;

  .berg-information-image-map-image {
    position: fixed;
    width: 41%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    z-index: 100;
    border-radius: 10px;
    background: white;
    padding: 15px;
    box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      width: 99%;
    }
  }
}
