@use 'src/styles/colors';

.order-contact-data {
  margin-left: 10px;

  &__label {
    font-size: 1.125rem;
    font-weight: 500;
    color: #2d5638;
    margin: 1rem 0;
  }

  &__text {
    padding-bottom: 10px;
    text-align: start;

    p {
      font-size: 0.875rem;
      line-height: 21px;
      font-weight: 500;
      color: colors.$gray-600;

      span {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}
