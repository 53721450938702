@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-tourist-attractions {
  margin-top: 2vw;
}

figcaption {
  align-self: center;
}

.berg-components-tourist-attractions[data-berg-layout='leftText'] {
  text-align: left;
  background-color: colors.$white;
  color: colors.$black;
  display: grid;
  grid-template-columns: 60% 40%;
}

.berg-components-tourist-attractions[data-berg-layout='leftText'] figcaption {
  padding: 3% 8% 3% 10%;
}

.berg-components-tourist-attractions figcaption h1 {
  font-size: 50px;
  font-weight: 700;
  color: colors.$primary-dark;
  margin-bottom: 3rem;
}

.berg-components-tourist-attractions figcaption p span {
  font-weight: 600;
}

.berg-components-tourist-attractions figcaption p {
  padding: 0 3rem 2rem 0;
  font-size: 15px;
  font-weight: 400;
}

.berg-components-tourist-attractions[data-berg-layout='leftText'] picture {
  justify-self: end;
  overflow: hidden;
}

.berg-components-tourist-attractions picture img {
  width: 100%;
  height: 100%;
}

.berg-components-tourist-attractions-buttons {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  text-decoration: none;

  button {
    width: 184px;
    height: 43px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;
    transition: color 0.2s, border-color 0.2s, background-color 0.2s;
  }

  & > .berg-components-tooltip .t-top:before {
    white-space: break-spaces;
  }

  &__for-booking {
    background-color: colors.$primary-light;
    color: colors.$white;

    &:hover,
    &:active {
      background-color: colors.$primary-dark;
    }
  }

  &__for-read-more {
    background-color: colors.$white;
    color: colors.$primary-light;
    border: 2px colors.$primary-light solid;
    width: 157px;

    &:hover,
    &:active {
      color: colors.$primary-dark;
      border-color: colors.$primary-dark;
    }
  }

  &__is-season {
    background-color: colors.$gray-100;
    color: colors.$gray-300;
  }
}

.berg-components-tourist-attractions[data-berg-layout='rightText'] {
  text-align: left;
  background-color: colors.$white;
  color: colors.$black;
  display: grid;
  grid-template-columns: 40% 60%;
}

.berg-components-tourist-attractions[data-berg-layout='rightText'] picture {
  justify-self: start;
  overflow: hidden;
}

.berg-components-tourist-attractions[data-berg-layout='rightText'] figcaption {
  padding: 3% 8% 3% 10%;
}

@media (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-components-tourist-attractions figcaption h1 {
    font-size: 24px;
    margin-bottom: 1.5rem;
  }

  .berg-components-tourist-attractions figcaption p {
    text-align: start;
    margin: 0 1rem 1.5rem 1rem;
    padding: 0;
  }

  .berg-components-tourist-attractions-buttons {
    gap: 2rem;
  }

  .berg-components-tourist-attractions-buttons button,
  .berg-components-tourist-attractions-buttons button:last-child {
    width: 169px;
  }

  .berg-components-tourist-attractions[data-berg-layout='leftText']
    picture
    img {
    margin: -100px 0 -141px 0;
  }

  .berg-components-tourist-attractions[data-berg-layout='rightText']
    picture
    img {
    margin: -100px 0 -141px 0;
  }
}

@media (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-components-tourist-attractions[data-berg-layout='leftText'] {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
  }

  .berg-components-tourist-attractions[data-berg-layout='leftText'] figcaption {
    padding: 3%;
  }

  .berg-components-tourist-attractions[data-berg-layout='rightText'] {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .berg-components-tourist-attractions[data-berg-layout='rightText']
    figcaption {
    padding: 3%;
  }

  .berg-components-tourist-attractions figcaption p {
    margin: 0 1rem 1.5rem 1rem;
    padding: 0;
  }

  .berg-components-tourist-attractions-buttons {
    justify-content: center;
  }
}
