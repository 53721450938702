@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-navigation {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 50px;
  color: colors.$white;
  z-index: 2;
  font-size: 14px;
  background-color: colors.$black-alpha-50;

  .berg-components-menu-item {
    flex: 1 0 auto;
    height: 100%;
    white-space: nowrap;

    & > * {
      transition: background-color 0.15s;
    }

    &:hover > *:not(.navigation-weather) {
      background-color: colors.$black-alpha-30;
    }
  }

  .navigation-weather {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;

    img {
      height: 24px;
    }

    div {
      line-height: 1em;
      margin-left: 0.35em;
    }
  }
}

.berg-components-navigation-list {
  display: none;
  align-items: center;
  height: 100%;
}

.berg-components-menu-item-language {
  min-width: 60px;
  white-space: nowrap;
  // height: 50px;
}

.berg-components-navigation .berg-components-navigation-picture {
  display: grid;
  place-items: center;
  flex: 0 0 125px;
  background-color: colors.$white;
}

.berg-components-navigation-picture a {
  height: 28px;
}

.berg-components-navigation-picture a img {
  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    height: 114%;
  }
}

.berg-components-menu-item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-decoration: none;
}

.berg-components-navigation .berg-components-menu-item a.active {
  font-weight: 600;
}

.berg-components-navigation .berg-components-menu-item-bold-template {
  display: block;
  font-weight: 600;
  height: 0;
  visibility: hidden;
}

.berg-components-menu-item-bold-template {
  display: none;
}

.berg-components-navigation-hamburger-btn {
  display: flex;
  align-items: center;
  font-size: 18px;
  background: none;
  padding: 0 15px;
}

.berg-components-navigation-search-btn {
  display: flex;
  align-items: center;
  font-size: 18px;
  background: none;
  padding: 0 15px;
  border-right: 1px solid colors.$white;
}

.berg-components-navigation-cta {
  flex: 2;
  min-width: 120px;
  max-width: 200px;
  height: 100%;
  margin-left: auto;
  background-color: colors.$orange-500;
  transition: background-color 0.15s;
}

.berg-components-navigation-cta-disabled {
  flex: 2;
  min-width: 120px;
  max-width: 200px;
  height: 100%;
  margin-left: auto;
  background-color: colors.$light-grey;
  color: colors.$darker-grey;
  transition: background-color 0.15s;

  @media screen and (max-width: 1023px) {
    [data-tooltip].t-top:before {
      top: 352%;
      margin-bottom: -37px;
    }

    [data-tooltip].t-top:after {
      top: 265%;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    height: 100%;
    font-weight: 500;
    text-decoration: none;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      font-size: 11.5px;
      white-space: nowrap;
    }
  }
}

.berg-components-navigation-cta:hover {
  background-color: colors.$dark-orange;
}

.berg-components-navigation-cta a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  height: 100%;
  font-weight: 500;
  text-decoration: none;
  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    font-size: 10.5px;
  }
}

@media screen and (min-width: map-get(vars.$breakpoints, 'robert')) {
  .berg-components-navigation-hamburger-btn,
  .berg-components-navigation-search-btn {
    display: none;
  }

  .berg-components-navigation {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: min(1360px, calc(100vw - 60px));
    background-color: transparent;
  }

  .berg-components-navigation .berg-components-navigation-picture {
    border-radius: 5px 0 0 5px;
  }

  .berg-components-navigation .berg-components-navigation-list {
    flex: 7;
    display: flex;
    width: 100%;
    background-color: colors.$black-alpha-50;
    border-radius: 0 5px 5px 0;
  }

  .berg-components-navigation-cta {
    margin-left: unset;
    border-radius: 0 5px 5px 0;
  }

  .berg-components-menu-item-language,
  .berg-components-menu-item-shopping-cart {
    height: 100%;
  }
}

.berg-components-navigation .berg-components-navigation-dropdown,
.berg-components-navigation .berg-components-language-dropdown {
  height: 100%;
}

.berg-components-navigation .berg-components-navigation-dropdown button,
.berg-components-navigation button {
  height: 100%;
  font-size: 14px;
  font-weight: 300;
}

.berg-components-navigation .berg-components-navigation-dropdown-list {
  top: 51px;
}

.berg-components-navigation
  .berg-components-navigation-dropdown[aria-expanded='true'] {
  background-color: colors.$black-alpha-30;
}

.berg-components-navigation .berg-components-navigation-dropdown-list {
  background: colors.$black-alpha-60;
  color: colors.$white;
  min-width: 100%;
}

.berg-components-navigation .berg-components-navigation-dropdown-list li a {
  min-height: 50px;
}

@media (min-width: 1025px) and (max-width: 1039px) {
  .berg-components-menu-item-shopping-cart {
    display: block;
  }
}
