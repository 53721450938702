@use 'src/styles/colors';
@use 'src/styles/vars';
@use 'src/styles/mixins';

.berg-information-popup-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.berg-information-popup {
  position: fixed;
  // top: 50%;
  // right: 26%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  overflow-y: scroll; /* Dodaje pionowy pasek przewijania, gdy zawartość jest większa niż rozmiar okna */
  max-height: calc(100vh - 20px);

  width: 90%;
  // min-width: 600px;
  height: auto;
  border-radius: 10px;
  background: white;
  padding: 15px;
  box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);

  h3 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
  }

  strong {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  ul {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    width: 90%;
    h3 {
      font-size: 12px;
    }

    p,
    strong,
    ul {
      font-size: 10px;
    }
  }
}
