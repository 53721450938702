@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

@keyframes btn-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.berg-components-basket-sticky-cta {
  &-container {
    position: fixed;
    bottom: 200px;
    right: 80px;
    z-index: 1;
    text-decoration: none;
  }

  width: 80px;
  height: 80px;
  border-radius: 50px;
  color: colors.$white;
  box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;

  &:hover {
    align-content: space-between;
    width: 290px;
    height: 100px;
    border-radius: 5px;
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &__icon svg {
    width: 40px;
    height: 40px;
    margin-top: 7px;
    margin-left: 18px;
  }

  &:hover &__icon {
    border-radius: 50px;
    border: 1px #8db742 solid;
    background-color: #8db742;
    position: fixed;
    right: 320px;
    bottom: 200px;
    width: 100px;
    height: 100px;

    svg {
      width: 50px;
      height: 50px;
      margin-top: 24px;
      margin-right: 18px;
    }
  }

  &__text {
    visibility: hidden;
  }

  &:hover &__text {
    visibility: visible;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    margin-left: 80px;
  }

  &__price {
    font-size: 32px;
    font-weight: 800;
    color: colors.$black;
  }

  &__quantity {
    position: absolute;
    left: -4px;
    bottom: -4px;
    width: 28px;
    height: 28px;
    line-height: 24px;
    font-size: 16px;
    color: colors.$black;
    background-color: colors.$white;
    border-radius: 50%;

    &[aria-hidden='true'] {
      visibility: hidden;
    }
  }

  @media (max-width: map-get(vars.$breakpoints, 'small')) {
    &-container {
      position: fixed;
      bottom: 200px;
      right: 10px;
      z-index: 1;
    }

    width: 50px;
    height: 50px;

    &__icon svg {
      width: 26px;
      height: 26px;
      display: flex;
      margin-top: 3px;
      margin-left: 11px;
    }

    &:hover &__icon {
      right: 165px;
      bottom: 200px;
      width: 70px;
      height: 70px;

      svg {
        width: 40px;
        height: 40px;
        margin-top: 15px;
        margin-left: 14px;
      }
    }

    &:hover {
      width: 200px;
      height: 70px;
    }

    &:hover &__text {
      font-size: 12px;
      margin-left: 70px;
    }

    &__price {
      font-size: 26px;
      font-weight: 800;
      color: colors.$black;
    }
  }
}

[data-client-style='brama'].berg-components-basket-sticky-cta {
  background: $primary-color-brama;
  &:hover {
    background: white;
    border: 2px solid $primary-color-brama;
    color: $primary-color-brama;
  }

  &:hover &__icon {
    background: $primary-color-brama;
    border: 2px solid $primary-color-brama;
  }

  &__quantity {
    border: 2px solid $primary-color-brama;
  }
}

[data-client-style='flisacy'].berg-components-basket-sticky-cta {
  background: $primary-color-flisacy;
  &:hover {
    background: white;
    border: 2px solid $primary-color-flisacy;
    color: $primary-color-flisacy;
  }

  &:hover &__icon {
    background: $primary-color-flisacy;
    border: 2px solid $primary-color-flisacy;
  }

  &__quantity {
    border: 2px solid $primary-color-flisacy;
  }
}

[data-client-style='polana'].berg-components-basket-sticky-cta {
  background: $primary-color-polana;
  &:hover {
    background: white;
    border: 2px solid $primary-color-polana;
    color: $primary-color-polana;
  }

  &:hover &__icon {
    background: $primary-color-polana;
    border: 2px solid $primary-color-polana;
  }

  &__quantity {
    border: 2px solid $primary-color-polana;
  }
}

[data-client-style='potok'].berg-components-basket-sticky-cta {
  background: $primary-color-potok;
  &:hover {
    background: white;
    border: 2px solid $primary-color-potok;
    color: $primary-color-potok;
  }

  &:hover &__icon {
    background: $primary-color-potok;
    border: 2px solid $primary-color-potok;
  }

  &__quantity {
    border: 2px solid $primary-color-potok;
  }
}
