@use 'src/styles/colors';
@use 'src/styles/vars';

@mixin dropdown-arrow {
  position: absolute;
  background-color: colors.$lighter-grey;
  right: 0;
  top: 0;
  bottom: 0;
  width: 42px;
  height: auto;
  border: initial;
}

@mixin dropdown-arrow-closed {
  position: absolute;
  top: 36%;
  left: 42%;
  transform: rotate(45deg);
  border-bottom: 2px solid colors.$darker-grey;
  border-right: 2px solid colors.$darker-grey;
  content: '';
  width: 8px;
  height: 8px;
}

@mixin dropdown-arrow-opened {
  position: absolute;
  top: 42%;
  left: 42%;
  transform: rotate(-135deg);
  border-bottom: 2px solid colors.$darker-grey;
  border-right: 2px solid colors.$darker-grey;
  content: '';
  width: 8px;
  height: 8px;
}

@mixin ticket-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 284px;
  background-color: colors.$white;
  margin-bottom: 24px;

  @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
    flex-direction: row;
  }

  &.highlighted-ticket {
    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      outline: 12px solid colors.$primary-dark;
      margin: 12px 12px 36px 12px;
    }
  }
}

@mixin ticket-picture {
  flex: 1 1 270px;
  overflow: hidden;
  text-align: start;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      width: auto;
      object-fit: contain;
    }
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
    &.highlighted-ticket {
      border: unset;
    }
  }
}

@mixin ticket-content {
  flex: 2 1 auto;
}

@mixin subpage-header {
  position: relative;
  min-height: 45vmin;
  margin-top: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;

  h1 {
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(max(-590px, -40vw), -50%);
    font-weight: 200;
    font-size: 30px;
    color: colors.$white;
    z-index: 2;
    text-shadow: 0px 4px 4px colors.$black-alpha-60;
    text-transform: uppercase;
    padding: 10px 15px;
    line-height: 1.2em;
    text-align: start;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      font-size: 40px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      font-size: 60px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'robert')) {
      font-size: 80px;
    }
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'robert')) {
    margin-top: 0;
  }
}

@mixin cursor-pointer {
  & *,
  &::after,
  &::before,
  & *::after,
  & *::before {
    cursor: pointer;
  }
}
