@use 'src/styles/colors';
@use 'src/styles/vars';

.navigation-weather {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    flex-direction: column;
    gap: 0px;
  }

  img {
    height: auto !important;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      height: 35px !important;
    }
  }
  div {
    color: #616161;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      font-size: 16px;
    }
  }
}
