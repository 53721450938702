@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../../styles/env-variables';

.cart-summary-ticket-info {
  font-weight: 400;
  display: list-item;
}

.berg-pages-cart-table {
  margin: 10px 0 20px;
  max-width: 1120px;
}

.cart-summary-footer {
  min-width: 450px;
  &__link {
    text-decoration: none;
  }

  .berg-checkbox {
    align-items: center;
    width: 110%;

    label {
      font-size: 14px;
      padding-right: 0;
      font-weight: 600;
    }
  }

  &__summary {
    align-self: end;
  }

  &__img {
    align-self: end;
    width: 100%;
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      align-self: center;
      width: 75%;
    }
  }

  &__desktop {
    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: none;
    }
  }

  &__mobile {
    display: none;

    @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
      display: block;
    }
  }

  [data-tooltip].t-top:before {
    text-wrap: wrap;
  }
}

[data-client-style='brama'].cart-summary-footer__link {
  color: $primary-color-brama;
}

[data-client-style='flisacy'].cart-summary-footer__link {
  color: $primary-color-flisacy;
}

[data-client-style='polana'].cart-summary-footer__link {
  color: $primary-color-polana;
}

[data-client-style='potok'].cart-summary-footer__link {
  color: $primary-color-potok;
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .cart-summary-all-footer {
    display: flex;
    flex-direction: column;
  }

  .cart-summary-footer:first-child {
    align-self: center;

    & button {
      align-self: center;
    }
  }

  .cart-summary-footer:last-child {
    align-self: center;
  }

  .cart-summary-footer__summary {
    align-self: center;
  }

  .cart-summary-footer__button {
    align-self: center;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'xm')) {
  .cart-summary-footer {
    min-width: 0;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-stack.berg-stack--column.cart-summary-footer:first-child {
    gap: 1rem !important;
  }
}
