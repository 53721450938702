$primary-color-brama: #2d5638;
$primary-color-flisacy: #2484da;
$primary-color-polana: #bd1330;
$primary-color-potok: #ef8424e6;

$primary-light-color-brama: #2d563842;
$primary-light-color-flisacy: #2485da4b;
$primary-light-color-polana: #bd132f4f;
$primary-light-color-potok: #ef83243f;

$primary-dark-color-brama: #152719;
$primary-dark-color-flisacy: #1e6aad;
$primary-dark-color-polana: #a5152d;
$primary-dark-color-potok: #ce6e20;

// kolor starego cta  #8db742;
// kolor buttona w karcie   background-color: #235789;
