@use 'src/styles/colors';

.berg-page-privacy-policy {
  text-align: left;
  padding: 0 10vw 0 10vw;
}

.berg-page-privacy-policy h1 {
  font-size: 40px;
  color: colors.$primary-light;
  margin: 4rem 0 4rem 0;
}

.berg-page-privacy-policy-paragraph p {
  font-size: 17px;
  margin-bottom: 1.5rem;
}

.berg-page-privacy-policy-paragraph p a {
  color: colors.$primary-light;
}

.berg-page-privacy-policy h2 {
  font-size: 30px;
  color: colors.$primary-light;
  margin: 2rem 0 2rem 0;
}

.berg-page-privacy-policy ul li {
  list-style-position: inside;
  list-style-type: disc;
}
