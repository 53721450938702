@use 'src/styles/colors';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.cart-products {
  &__product-wrapper {
    width: 100%;
  }
  &__product-header {
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
  }
  &__summary-header {
    font-size: 1.25rem;
    font-weight: 600;
    justify-self: start;
  }
  &__summary-header-location {
    font-size: 1.125rem;
    font-weight: 500;
    justify-self: start;
    color: colors.$almost-grey;
  }
  &__buttons {
    & > div:last-child {
      display: flex;
      gap: 2.25rem;
      margin-top: 1rem;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        display: flex;
        flex-direction: column;
        align-self: center;
      }
    }
  }

  .category-header {
    font-weight: 500;
    font-size: 0.8125rem;
    justify-self: start;
    padding-left: 1rem;
  }
  .category-quantity {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: center;
    color: colors.$light-grey;
  }
  .category-price-light {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: end;
    color: colors.$light-grey;
  }
  .category-price {
    font-size: 1.1875rem;
    font-weight: 600;
    justify-self: end;
  }
  .category-price-location {
    font-size: 1.1875rem;
    font-weight: 500;
    justify-self: end;
    color: colors.$almost-grey;
  }
  .category-operations {
    justify-self: end;
  }
  .validity-date {
    font-style: italic;
    font-size: 0.75rem;
    color: colors.$light-grey;
    text-align: left;
  }
  .data-carrier {
    font-size: 0.75rem;
  }
}

.cart-managements-status-label {
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 3.5rem;
  text-align: start;
}

[data-client-style='brama'].cart-managements-status-label {
  color: $primary-color-brama;
}

[data-client-style='flisacy'].cart-managements-status-label {
  color: $primary-color-flisacy;
}

[data-client-style='polana'].cart-managements-status-label {
  color: $primary-color-polana;
}

[data-client-style='potok'].cart-managements-status-label {
  color: $primary-color-potok;
}

.berg-table-discount {
  display: flex;
  flex-direction: row;
  gap: 5px;

  span {
    text-decoration: line-through;
    color: colors.$light-grey;
    font-weight: 500;
  }
}

.accommodations-table {
  table.berg-components-order-table td:nth-child(2) {
    position: absolute;
    margin-right: 8.5%;
    top: 4px;
    right: 61%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    padding-right: 16%;
    top: 4px;
    right: 51%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-top: 12px;
      margin-left: -5%;
      margin-right: 20px;
    }

    @media (max-width: map-get(vars.$breakpoints, 'medium')) {
      margin-left: -7.5%;
      width: 20%;
      white-space: nowrap;
    }

    @media (max-width: 556px) {
      margin-left: 1%;
      margin-top: 10px;
      width: auto;
    }

    @media (max-width: map-get(vars.$breakpoints, 'small')) {
      padding-top: 10px;
      margin-top: 0;
      margin-left: -5%;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 4.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    top: 4px;
    right: 42%;
    display: flex;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding: 0;
      margin-top: 5%;
      display: grid;
      text-align: left;
    }

    @media (max-width: map-get(vars.$breakpoints, 'small')) {
      margin-top: 5%;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    left: 62%;
    top: 4px;
    right: 32.5%;
    padding-left: 0px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding: 0;
      margin-top: 5%;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    top: 0px;
    right: 18%;
    padding-left: 5%;
    white-space: nowrap;
    margin-top: 0.4%;
    text-align: left;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      margin-top: 5%;
      margin-right: 0;
      padding: 0;
      white-space: wrap;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 4%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(7) {
    position: absolute;
    top: 4px;
    right: 11%;
    width: 10%;
    padding-left: 10%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding: 0;
      margin-top: 5%;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(8) {
    position: absolute;
    top: 4px;
    right: 1%;
    left: 96%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      display: flex;
      flex-direction: column;
      justify-content: end;
      width: 10%;
      margin-left: 5%;
      margin-top: 5%;
    }

    @media (max-width: map-get(vars.$breakpoints, 'medium')) {
      margin-left: 10%;
    }

    @media (max-width: map-get(vars.$breakpoints, 'small')) {
      margin-left: 20%;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 2.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(9) {
    position: absolute;
    top: 4px;
    right: 0;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 5px;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  .max-width-th {
    max-width: 75px;
  }

  .width-date {
    width: 13%;
    text-align: center;
    padding-right: 5%;
  }

  .sum {
    text-align: center;
    padding-left: 3%;
  }

  table.berg-components-order-table td:first-of-type {
    padding: 20px 10px 10px 20px;
    width: 15%;
    top: -7px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      width: 10%;
      margin-bottom: 2%;
      margin-right: 165px;
      margin-left: -2.5%;
    }

    @media (max-width: map-get(vars.$breakpoints, 'medium')) {
      margin-left: -3%;
    }

    @media (max-width: map-get(vars.$breakpoints, 'small')) {
      width: 300px;
      margin-right: 0px;
      margin-left: -6%;
    }
  }
}

.parking-table-management {
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    padding-left: 15px;
    right: 12%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 25px;
    right: 14.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: -8%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}

.therms-table-management {
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 25px;
    right: 7%;
    padding-left: 5px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}

.skiPasses-table-management {
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 25px;
    right: 7%;
    padding-left: 5px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}

.attraction-table-management {
  table.berg-components-order-table td:nth-child(3) {
    position: absolute;
    top: 7px;
    // top: 25px;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(4) {
    position: absolute;
    margin-right: 8.5%;
    top: 7px;
    // top: 25px;
    right: 11.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(5) {
    position: absolute;
    top: 7px;
    // top: 25px;
    right: 7%;
    padding-left: 5px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  table.berg-components-order-table td:nth-child(6) {
    position: absolute;
    margin-right: 8.5%;
    top: 7px;
    // top: 25px;
    right: -6%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
    }

    @supports (-webkit-hyphens: none) {
      position: relative !important;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }
}
