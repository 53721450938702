@use 'src/styles/colors';

.order-summary-footer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  place-items: center;
  gap: 20px;
  
  &__promo-code {
    color: colors.$orange-500;
    border: 1px solid colors.$orange-500;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    padding: 4px;
  }
  
  &__item-header {
    font-size: 1.125rem;
    font-weight: 500;

    &--price-on-Site{
      font-size: 0.938rem;
      font-weight: 400;
      justify-self: end;
    }

    &--price-online{
      font-size: 0.938rem;
      font-weight: 600;
      justify-self: end;
    }

    &--bold {
      font-size: 1.125rem;
      font-weight: 600;
      justify-self: end;
    }
  }

  &__item-description {
    font-size: 1.125rem;
    font-weight: 500;
    justify-self: start;
    
    &--price-on-Site{
      font-size: 0.938rem;
      font-weight: 400;
      justify-self: start;
    }

    &--bold {
      font-size: 1.375rem;
      font-weight: 700;
      justify-self: start;
      
    }

    &--price-online{
      font-size: 1.125rem;
      font-weight: 700;
      justify-self: start;
    }
  }
}

.berg-stack.berg-stack--row.order-summary-footer {
  display: grid;
  grid-template-columns: 300px 100px;
  align-self: center;
}