@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';
@import '../../../styles/env-variables';

.berg-components-attractions {
  &-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    min-width: 321px;
    margin: 24px 0;
    box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);
    border-radius: 10px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      flex-direction: column;
      // height: 820px;
      box-shadow: none;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      flex-direction: row;
      box-shadow: none;
    }
  }

  &__promo {
    top: 0px;
    position: absolute;
    left: 43%;
    z-index: 2;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      left: 47%;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      left: 17%;
    }
  }

  &__box {
    // position: absolute;
    // top: 45.5%;
    // left: 27.5%;
    z-index: 2;
    padding: 4px 8px;
    align-items: center;

    margin-top: -12px;
    width: auto;
    align-self: center;
    height: 27px;
    border-radius: 8px;
    border: 1px solid #fff;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);

    color: #fff;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      // top: 44.5%;
      // left: 42.5%;
      margin-top: -62px;
      align-self: center;
      height: 32px;
      font-size: 14px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      // top: 47%;
      // left: 40%;
      height: 32px;
      font-size: 14px;
    }
  }

  &__picture {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 35%;
    border-radius: 16px 16px 0 0;
    background-color: colors.$white;

    img {
      position: relative;
      left: 0;
      right: 0;
      width: 100%;
      height: 48%;
      object-fit: cover;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      img {
        width: 100%;
        height: 53%;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      width: 36%;
      height: 100%;
      border-radius: 16px;
      box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);

      img {
        width: 100%;
        height: 50%;
        flex-shrink: 0;
      }
    }
  }

  &__content {
    flex: 1;
    border-radius: 0 0 16px 16px;
    background-color: colors.$white;

    display: grid;
    padding: 10px;
    gap: 10px;
    grid-template-rows: auto auto auto;
    justify-items: center;
    grid-template-areas:
      'info'
      'choices '
      'submit';

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      padding: 20px 15px 26px 15px;
      box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);
      border-radius: 16px;
      grid-template-rows: auto auto;
      grid-template-columns: 65% 30%;
      gap: 20px;
      grid-template-areas:
        'info info'
        'choices submit';
    }

    .name {
      display: flex;
      align-items: center;
      gap: 25px;

      svg {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__title {
    grid-area: title;

    color: #344054;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      text-align: start;
    }

    svg {
      width: 21px;
      height: 21px;
    }
  }

  &__line {
    grid-area: line;
    width: 100%;
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  &__line1 {
    grid-area: line1;
    width: 100%;
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  &__line2 {
    grid-area: line2;
    width: 100%;
    height: 1px;
    border: 1px colors.$light-grey solid;
    display: block;
    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      display: none;
    }
  }

  &__choices {
    grid-area: choices;
    display: flex;
    flex-direction: column;
    z-index: 1;
    gap: 20px;
    margin: 12px 0;
    justify-self: start;
    width: 100%;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0;
      align-items: flex-start;
      width: 100%;
      justify-content: space-between;
      margin-left: 15px;
      gap: 0;
    }

    .rectangle {
      border-radius: 4px;
      border: 0.5px solid #eee;
      padding: 8px 4px;
      flex: 1;

      div {
        color: #575971;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;

        span {
          color: #344054;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        button svg {
          width: 21px;
          height: 21px;
        }
      }

      @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
        width: calc(80% - 10px);
        margin-bottom: 10px;

        div {
          font-size: 15px;
          span {
            font-size: 15px;
          }
        }
      }
    }
  }
  // &__choices + &__line {
  //   display: block;

  //   @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  //     display: none;
  //   }
  // }

  &__choice-label-dropdown {
    align-self: center;
    margin-right: 20px;
  }

  &__sum {
    color: rgba(52, 64, 84, 0.4);
    grid-area: sum;
    text-wrap: nowrap;
    align-self: center;

    &--first {
      display: flex;
      align-items: center;
      gap: 3px;
      justify-content: center;

      color: #344054;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      p {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // text-decoration: line-through;
      }
    }

    &--second {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: end;

      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      p {
        color: #344054;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      div {
        color: #ed1c24;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }

    &--third {
      display: flex;
      align-items: center;
      gap: 3px;
      justify-content: end;

      color: rgba(52, 64, 84, 0.5);
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
      margin: 0;
    }
  }

  &__submit {
    grid-area: submit;
    z-index: 2;

    p {
      color: #575971;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-wrap: nowrap;
    }

    strong {
      color: #344054;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    button {
      color: colors.$white;
      font-size: 16px;
      font-weight: 500;
      padding: 6px 24px;
      line-height: 1;
      white-space: nowrap;
      height: 38px;
      align-self: center;
      border-radius: 8px;
      // width: 100%;
      height: 55.855px;
    }

    // @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
    //   justify-self: end;
    //   place-self: end;
    //   // width: 50%;
    //   height: 71.855px;
    // }

    button:disabled {
      background-color: colors.$light-grey !important;
    }
  }

  &__info {
    grid-area: info;
    gap: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-self: center;
    z-index: 2;

    strong {
      color: #585858;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px; /* 166.667% */
    }

    p {
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: start;
    }

    ul {
      text-align: start;
      list-style-position: outside;

      li {
        list-style-type: disc;
        margin: 10px 0 0 20px;

        @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
          margin: 4px 0 0 20px;
        }
      }
    }
    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: start;
      margin-left: 15px;
      gap: 30px;
    }
  }

  &__rules p {
    color: #344054;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 160%; /* 12.8px */
  }

  &__desc {
    color: #575971;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 12px */

    strong {
      color: #1b264f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }

    span {
      color: #1b264f;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 19.2px */
    }

    button {
      border: 1px solid #eee;
      border-radius: 8px;
      padding: 6px 24px;
    }

    button:hover {
      background-color: #cbceda;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      color: #575971;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 14.4px */

      strong {
        color: #1b264f;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }

      span {
        color: #1b264f;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 14.4px */
      }
    }
  }

  &__hour {
    color: #1b264f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */

    span {
      color: #1b264f;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: start;
    }

    .berg-components-dropdown-wrapper {
      color: #1b264f;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }
}

[data-client-style='brama'].berg-components-attractions {
  &__box {
    background: $primary-color-brama;
  }

  &__submit {
    button {
      background-color: $primary-color-brama;
    }

    button:not(:disabled):hover {
      background-color: $primary-dark-color-brama;
    }
  }
}

[data-client-style='flisacy'].berg-components-attractions {
  &__box {
    background: $primary-color-flisacy;
  }

  &__submit {
    button {
      background-color: $primary-color-flisacy;
    }

    button:not(:disabled):hover {
      background-color: $primary-dark-color-flisacy;
    }
  }
}

[data-client-style='polana'].berg-components-attractions {
  &__box {
    background: $primary-color-polana;
  }

  &__submit {
    button {
      background-color: $primary-color-polana;
    }

    button:not(:disabled):hover {
      background-color: $primary-dark-color-polana;
    }
  }
}

[data-client-style='potok'].berg-components-attractions {
  &__box {
    background: $primary-color-potok;
  }

  &__submit {
    button {
      background-color: $primary-color-potok;
    }

    button:not(:disabled):hover {
      background-color: $primary-dark-color-potok;
    }
  }
}
