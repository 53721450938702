@use 'src/styles/colors';
@use 'src/styles/vars';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.no-scroll {
  overflow-y: hidden;
  height: 100vh;
}

.berg-theme-winter {
  --primary-dark: var(--dark-blue);
  --primary-light: var(--blue);
  --primary-lighter: var(--light-blue);
}

.berg-theme-summer {
  --primary-dark: var(--very-perry);
  --primary-light: var(--light-very-perry);
  --primary-lighter: var(--lighter-very-perry);
}

.berg-styles-pricing-table {
  h2 {
    font-size: 25px;
    font-weight: bold;
    color: colors.$primary-dark;
    padding: 4rem 0 1rem 0;
    text-align: start;
  }

  &_label {
    color: colors.$black;
    font-size: 18px;
    font-weight: 700;
    min-height: 183px;
  }

  &__container {
    width: 100%;
    overflow-x: auto;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  table {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;
  }

  thead {
    font-weight: 500;
  }
  th {
    background-color: colors.$primary-dark;
    color: colors.$white;
  }
  tbody tr {
    background-color: colors.$light-blue;
    &:nth-child(even) {
      background-color: colors.$lighter-blue;
    }
  }
}

@media (max-width: 480px) {
  .berg-styles-pricing-table {
    h2 {
      font-size: 25px;
    }

    &__container {
      box-shadow: 0px 5px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }

    table {
      min-width: 650px;
    }

    th,
    td {
      font-size: 13px !important;
    }
  }
}

.berg-styles-pricing-table {
  th,
  td {
    border: 1px solid colors.$white;
    text-align: left;
    padding: 8px;
    text-align: center;
    font-size: 16px;
    white-space: pre-wrap;
  }
}

.Toastify {
  &__toast {
    min-height: 25px;
    padding: 0 8px;
    border-radius: 5px;
    font-weight: 600;
    font-family: 'Poppins';
    font-size: 12px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      min-height: 40px;
    }

    .berg-styles-snackbar-close {
      align-self: center;
      line-height: 0;
    }
  }

  &__toast-body {
    padding: 0;

    @media screen and (min-width: map-get(vars.$breakpoints, 'small')) {
      padding: 6px;
    }
  }

  &__toast-icon {
    height: 15px;
  }

  &__toast-theme--colored {
    &.Toastify__toast--info {
      background: colors.$primary-light;
    }

    &.Toastify__toast--success {
      background: colors.$green;
    }

    &.Toastify__toast--warning {
      background: colors.$warning-500;
    }

    &.Toastify__toast--error {
      background: colors.$error-500;
    }
  }
}
.berg-styles-discount {
  text-align: start;
  padding-bottom: 20px;
  p {
    color: colors.$primary-dark;
    font-size: 18px;
    font-weight: 500;
  }
}
