@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-extras {
  &-list-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 284px;
    background-color: colors.$white;
    margin-bottom: 24px;

    @for $i from 1 through 99 {
      &:nth-child(#{$i}) {
        z-index: $i * -1 + 100;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      flex-direction: row;
    }
  }

  &__list-picture {
    position: relative;
    overflow: hidden;
    width: 100%;

    img {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      width: 34%;
    }
  }

  &__list-line {
    height: 1px;
    border: 1px colors.$light-grey solid;
  }

  &__list-content {
    flex: 1;
    display: grid;
    padding: 0;
    grid-template-rows: auto 2fr auto auto auto;
    grid-template-areas:
      'title'
      'choices'
      'sum'
      'info'
      'submit';

    hr {
      margin-top: 17px;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      grid-template-rows: auto 2fr 1fr auto;
      padding: 26px 26px 26px 40px;
      grid-template-areas:
        'title title'
        'choices choices'
        'typ sum'
        'info submit';

      hr {
        margin: 0;
      }
    }
  }

  &__list-title {
    grid-area: title;
    padding: 17px 0;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    text-align: center;
    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      text-align: start;
      padding: 0;
    }
  }

  &__list-choices {
    grid-area: choices;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;

    & > div {
      display: flex;
      align-items: center;
      margin-top: 25px;

      &:first-of-type {
        z-index: 1;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      flex-direction: row;
      justify-content: flex-start;
      margin: 0;
    }

    label {
      margin: 0 11px 0 0;
      text-align: right;
      font-weight: 500;
      font-size: 14px;
      color: colors.$darker-grey;
    }

    input {
      margin-right: none;
      width: 200px;
      font-size: 14px;
      border-radius: 5px;

      @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
        width: 105px;
        border-radius: 0;
        margin-right: 16px;
      }
    }

    .berg-components-dropdown-wrapper {
      min-width: 200px;
      margin: none;

      @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
        width: 105px;
        margin: 0 30px 0 0;
      }
    }
  }

  &__input-dimensions {
    width: 105px;
    height: 43px;
    background-color: colors.$off-white;
  }

  &__list-sum {
    grid-area: sum;
    justify-self: center;
    align-self: end;
    font-size: 24px;
    font-weight: 600;
    margin-top: 26px;
    margin-right: 34px;

    &[aria-disabled='true'] {
      color: colors.$lighter-grey;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
      margin-right: 0;
    }
  }

  &__list-submit {
    grid-area: submit;
    justify-self: center;
    background-color: colors.$primary-dark;
    color: colors.$white;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 30px;
    line-height: 1;
    white-space: nowrap;
    height: 38px;
    width: 208px;
    margin: 10px 34px 23px 0;

    &:disabled {
      background-color: colors.$light-grey;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      justify-self: end;
      margin: 0;
    }
  }

  &__list-info {
    grid-area: info;
    display: none;
    font-size: 12px;
    color: colors.$darker-grey;
    text-align: start;
    line-height: 20px;
    margin-top: 26px;
    margin-left: 26px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      display: block;
      margin-top: 0;
      margin-left: 0;
    }
  }
}
