@use 'src/styles/colors';
@use 'src/styles/vars';

table.berg-components-order-table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border: 0px;

  thead {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.03em;

    th {
      background: colors.$gray-100;
      color: colors.$gray-400;
      padding: 10px 20px 10px 23px;

      &:first-of-type {
        color: colors.$gray-600;
        border-radius: 5px 0 0 5px;
        padding: 10px 10px 10px 20px;
      }

      &:last-of-type {
        border-radius: 0 5px 5px 0;
        padding: 10px 20px 10px 10px;
      }
    }
  }

  td {
    padding: 10px 20px 10px 0;

    &:first-of-type {
      position: relative;
      padding: 10px 10px 10px 20px;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
      padding: 10px 20px 10px 10px;
    }

    &.nowrap {
      white-space: nowrap;
    }
  }

  // td:nth-child(2) {
  //   position: relative;
  // }

  td:nth-child(2) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 48%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      top: 0;
      left: 1.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  td:nth-child(3) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 35.5%;
    padding-left: 20px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      top: 0;
      left: 3.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  td:nth-child(4) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 20%;
    text-align: center;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      top: 0;
      left: 1.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  td:nth-child(5) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 14.5%;
    padding-left: 90px;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      // top: 0;
      left: 1.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  td:nth-child(6) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 4.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      top: 0;
      left: 2.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  td:nth-child(7) {
    position: absolute;
    top: 7px;
    // top: 24px;
    right: 2.5%;
    @media (max-width: map-get(vars.$breakpoints, 'xm')) {
      position: unset;
      padding-left: 0;
    }

    @supports (-webkit-hyphens: none) {
      position: relative;
      top: 0;
      left: 0.5%;
      -webkit-align-self: center; //safari 7+
      align-self: center;
    }
  }

  tbody {
    font-weight: 600;
    font-size: 14px;

    tr {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      padding-left: 20px;
      counter-increment: counter;
    }
  }

  .table-credits {
    font-size: 12px;
  }

  /* MQ STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
  @media (max-width: calc(map-get(vars.$breakpoints, 'xm') - 1px)) {
    .visible\@l {
      display: none;
    }
    .full\@sm {
      width: 100%;
    }
    .w10\@sm {
      width: 10%;
    }
    .w36\@sm {
      width: 36%;
    }
    .w30\@sm {
      width: 30%;
    }
    .w25\@sm {
      width: 25%;
    }
    .w25\@sm {
      width: 20%;
    }
    .w50\@sm {
      width: 50%;
    }
    .w70\@sm {
      width: 70%;
    }

    td::before {
      content: attr(data-label);
      float: left;
      font-weight: 500;
      font-size: 10px;
      color: colors.$gray-400;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }

    tbody tr:not(:first-of-type) td:first-of-type {
      border-top: 1px solid colors.$gray-300;
      padding-top: 20px;
      margin-top: 10px;
    }

    td {
      &[data-label] {
        display: grid;
      }

      &:first-of-type {
        padding: 10px 20px 10px 0;
      }
    }
  }

  @media (min-width: map-get(vars.$breakpoints, 'xm')) {
    border: none;

    .full\@l {
      width: 100%;
    }
    .w5\@l {
      width: 5%;
    }
    .w10\@l {
      width: 10%;
    }
    .w15\@l {
      width: 15%;
    }
    .w30\@l {
      width: 30%;
    }
    .w25\@l {
      width: 25%;
    }
    .w40\@l {
      width: 40%;
    }
    .w35\@l {
      width: 35%;
    }
    .w50\@l {
      width: 50%;
    }
    .w70\@l {
      width: 70%;
    }

    td:first-child {
      padding-right: 0;
    }

    tbody {
      tr {
        display: table-row;
      }

      tr::before {
        display: none;
      }
    }
  }
}
