@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';

.accommodation-criteria {
  padding-bottom: 38px;
  z-index: 1;

  @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
    border-top: 1px solid colors.$grey;
    border-bottom: 1px solid colors.$grey;
  }

  @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
    padding-bottom: 10px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;

    .berg-components-dropdown__control {
      background-color: colors.$white;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      & > div {
        align-self: flex-start;
      }

      flex-direction: row;
      height: 43px;
    }

    & > hr {
      border-bottom: 1px solid colors.$light-grey;
      width: 100%;

      @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
        display: none;
      }
    }
  }

  &__period-wrapper {
    position: relative;
    z-index: 2;
  }

  &__period {
    position: relative;
    display: flex;
    align-items: center;
    height: 43px;
    padding: 0 43px 0 20px;
    min-width: 330px;
    background-color: colors.$white;
    border-radius: 2px;

    .berg-icons-calendar path {
      fill: colors.$light-grey;
    }
  }

  &__period-dates {
    margin: 0 30px;
    user-select: none;
  }

  &__period-arrow {
    border-radius: 0 2px 2px 0;
    @include mixins.dropdown-arrow;

    &[aria-expanded='true']::before {
      @include mixins.dropdown-arrow-opened;
    }

    &[aria-expanded='false']::before {
      @include mixins.dropdown-arrow-closed;
    }
  }

  &__calendar-wrapper {
    position: absolute;
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: colors.$primary-light;
    padding: 8px;
    border-radius: 8px;

    &[aria-hidden='true'] {
      display: none;
    }
  }

  &__calendar {
    border-radius: 8px;
    background-color: colors.$white;
  }

  &__adults-count,
  &__children-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: colors.$lighter-grey;
    width: 42px;
    height: 100%;
  }

  &__children {
    position: relative;

    .berg-components-tooltip {
      position: absolute;
      top: 5px;
      left: 10px;
    }
  }

  &__search {
    background-color: colors.$primary-light;
    color: colors.$white;
    width: 200px;
    height: 60px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 8px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      margin-left: auto;
    }
  }

  &__persons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 1;
    max-width: 380px;

    @media screen and (min-width: map-get(vars.$breakpoints, 'xm')) {
      margin-left: 35px;
    }
  }

  &__validation-error {
    display: flex;
    align-items: center;
    color: colors.$error-500;
    font-size: 10px;
    font-weight: 500;
    border-radius: 4px;
    padding: 2px;

    svg {
      height: 1em;
      z-index: -1;
    }
  }

  [aria-invalid='true'] {
    outline: 1px solid colors.$error-500;
  }
}

.search-number-field {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 18px;
  height: 100%;
  width: fit-content;
  background-color: colors.$white;
  border-radius: 2px;
  height: 43px;

  .berg-icons-adult,
  .berg-icons-child {
    transform: scale(0.8);

    path {
      fill: colors.$light-grey;
    }
  }

  .berg-components-tooltip {
    position: absolute;
    top: -8px;
    left: -14px;
  }

  &__icon {
    position: relative;
    line-height: 0;
  }

  &__caret {
    display: flex;
    height: 42px;
  }

  &__arrow {
    position: relative;
    background-color: colors.$lighter-grey;
    height: 100%;
    width: 42px;
    border-radius: 0 2px 2px 0;

    &[aria-expanded='true']::before {
      @include mixins.dropdown-arrow-opened;
    }

    &[aria-expanded='false']::before {
      @include mixins.dropdown-arrow-closed;
    }
  }

  &__dropdown-list {
    display: none;
    position: absolute;
    top: 105%;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      right: -3%;
    }

    &[aria-expanded='true'] {
      display: block;
    }
  }
}
