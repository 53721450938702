@use 'src/styles/colors';
@use 'src/styles/mixins';
@use 'src/styles/vars';
@import '../../styles/env-variables';

.berg-components-attractions-template {
  display: grid;
  // grid-template-columns: auto auto;
  justify-content: normal;
  z-index: 1;
  position: relative;
  z-index: 0;

  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    grid-template-columns: auto;
    padding: 0 15px;
  }

  &__filter-button {
    p {
      color: #181818;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px; /* 250% */
      letter-spacing: 0.2px;
      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        font-size: 10px;
        line-height: 20px; /* 200% */
        letter-spacing: 0.1px;
      }
    }

    a {
      border-radius: 16px;
      border: 2px solid #c1c1c1;
      display: flex;
      height: 48px;
      padding: 12px 32px 12px 16px;
      justify-content: center;
      align-items: center;
      gap: 16px;

      color: #616161;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 40px; /* 250% */
      letter-spacing: 0.2px;

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        height: 24px;
        padding: 6px 16px 6px 8px;
        gap: 8px;

        font-size: 10px;
        line-height: 20px; /* 200% */
        letter-spacing: 0.1px;

        svg {
          width: 16px;
          height: 16px;
          padding: 0.889px;
        }
      }
    }

    span {
      color: #575971;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 14px */

      @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
        font-size: 10px;
      }
    }
  }

  .button-all {
    border-radius: 16px;
    border: 2px solid #c1c1c1;
    display: flex;
    height: 48px;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
      height: 24px;
      padding: 6px 16px;
      gap: 8px;

      font-size: 10px;
      line-height: 20px; /* 200% */
      letter-spacing: 0.1px;
    }
  }

  a.button-inactive {
    background-color: transparent; /* Kolor dla nieaktywnego stanu */
  }

  .berg-information-image-map-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;

    .berg-information-image-map-image {
      position: fixed;
      width: 41%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);
      z-index: 100;
      border-radius: 10px;
      background: white;
      padding: 15px;
      box-shadow: 0px 3px 6px 1px rgba(57, 122, 218, 0.25);

      @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
        width: 99%;
      }
    }
  }
}

.berg-components-attractions-template__filter-button
  a.button-active[data-client-style='brama'] {
  background-color: $primary-color-brama;
  color: white;

  svg g path {
    fill: white;
  }
}

.berg-components-attractions-template__filter-button
  a.button-active[data-client-style='flisacy'] {
  background-color: $primary-color-flisacy;
  color: white;

  svg g path {
    fill: white;
  }
}

.berg-components-attractions-template__filter-button
  a.button-active[data-client-style='polana'] {
  background-color: $primary-color-polana;
  color: white;

  svg g path {
    fill: white;
  }
}

.berg-components-attractions-template__filter-button
  a.button-active[data-client-style='potok'] {
  background-color: $primary-color-potok;
  color: white;

  svg g path {
    fill: white;
  }
}
