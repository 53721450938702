@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-components-search {
  display: none;
  position: absolute;
  bottom: -38px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  min-width: 1100px;
  height: 76px;
  background-color: colors.$white;
  overflow: hidden;

  @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
    display: flex;
  }

  &--mobile {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50px;
    width: 100%;
    height: calc(100vh - 50px);
    overflow-y: scroll;
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 132px;
    white-space: nowrap;
    color: colors.$white;
    background-color: colors.$primary-light;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    font-size: 15px;
    font-weight: 500;
  }

  &--mobile &__title {
    clip-path: initial;
    flex: 0 0 53px;
  }

  &__params-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    flex: 1 1 auto;
    overflow: hidden;

    & > div {
      height: 100%;
      padding: 0 26px;

      &:not(:last-child) {
        border-right: 1px solid colors.$darker-grey;
      }
    }
  }

  &--mobile &__params-wrapper {
    justify-content: flex-start;
    flex-direction: column;
    padding: initial;
    z-index: 1;

    & > div {
      width: 100%;
      flex: 0 1 88px;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          z-index: $i * -1 + 5;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid colors.$grey;
        border-right: initial;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 250px;
    color: colors.$white;
    background-color: colors.$primary-dark;
    font-size: 18px;
    font-weight: 600;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10% 34%;
      text-decoration: none;
    }
  }

  &--mobile &__button {
    flex: 0 0 76px;
  }
}

.search-date {
  &,
  & > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
      cursor: pointer;
    }
  }

  svg {
    flex: none;
  }

  &__label {
    min-width: 110px;
    margin-left: 10px;
    cursor: pointer;
  }

  &__calendar {
    position: absolute;
    top: 105%;
    border: 1px solid colors.$primary-dark;
    border-radius: 8px;
    overflow: hidden;
  }
}

.search-number-field {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex: none;
  }

  .berg-components-tooltip {
    position: absolute;
    top: -8px;
    left: -14px;
  }

  &__icon {
    position: relative;
  }

  &__label {
    margin: 0 10px 0 16px;
    white-space: nowrap;

    &,
    & * {
      cursor: pointer;
    }

    div:nth-of-type(1) {
      height: 0;
      visibility: hidden;
    }

    div:nth-of-type(2) {
      height: auto;
      visibility: visible;
    }

    &--active {
      div:nth-of-type(1) {
        height: auto;
        visibility: visible;
      }

      div:nth-of-type(2) {
        height: 0;
        visibility: hidden;
      }
    }
  }

  &__caret {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      pointer-events: none;
    }
  }

  &__choice-number-persons {
    display: none;
    position: absolute;
    top: 105%;

    &--visible {
      display: block;
    }
  }

  &__input {
    height: 0;
    width: 0;
    visibility: hidden;
  }
}
