@use 'src/styles/colors';

.berg-components-discount-message {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  svg {
    width: 10px;
    height: 10px;
  }

  p {
    color: colors.$orange-500;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
  }
}
