@use 'src/styles/colors';
@use 'src/styles/vars';

.berg-text-area-input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: 7.5%;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 2rem;
    padding-right: 1vw;
    text-align: right;
    color: #4e4e4e;
  }
}

.additionalInformation {
  box-sizing: border-box;
  padding: 1rem;
  background: #fcfcfd;
  border: 1px solid #dfdfe6;
  border-radius: 12px;
  height: 150px;
  width: 100%;
  margin-right: 37.5%;
  resize: none;
  text-align: left;
  @media screen and (max-width: map-get(vars.$breakpoints, 'large')) {
    width: 100%;
    margin-right: 10%;
  }
  @media screen and (max-width: map-get(vars.$breakpoints, 'medium')) {
    width: 100%;
    margin-right: 0%;
  }
}

.berg-pages-cart-data {
  &__footer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 775px;

    label[role='placeholder'] {
      width: 28%;
    }

    .berg-checkbox {
      flex: 1;
      white-space: nowrap;
    }

    p {
      margin-left: auto;
      font-weight: 500;
      font-size: 16px;

      &::before {
        content: '* ';
        color: colors.$orange-500;
      }
    }

    button {
      text-transform: uppercase;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
      width: 90%;
    }

    @media screen and (min-width: map-get(vars.$breakpoints, 'large')) {
      width: 70%;
    }
  }

  &__phone-prefix {
    width: 4rem;
    margin: 0 18px 0 0;
    margin-left: 46px;
  }

  label[for='phone'] {
    width: 42%;
  }

  &__phone-input {
    display: block;
  }
}

.berg-components-info {
  max-width: 471px;
  border-radius: 5px;
  background-color: colors.$blue;

  display: flex;
  padding: 3px 7px;
  align-items: center;

  p {
    color: colors.$white;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;
  }
}

@media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
  .berg-pages-cart-data__phone-input {
    width: 73%;
  }
}

@media screen and (max-width: map-get(vars.$breakpoints, 'small')) {
  .berg-pages-cart-data__phone-input {
    display: block;
    .berg-text-input {
      display: grid;
      grid-template-areas:
        'label label'
        'input_prefix input_tel';
    }
    label {
      grid-area: label;
    }
  }

  .berg-pages-cart-data__phone-prefix {
    margin-left: 0;
  }

  .berg-pages-cart-data__footer {
    flex-direction: column-reverse;
    gap: 12px;

    p {
      text-align: start;
      margin-left: 0;
    }
  }
}
