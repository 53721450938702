@use './src/styles/colors';
@use './src/styles/vars';

.berg-components-out-of-service {
  position: fixed;
  row-gap: 10px;
  align-items: center;
  bottom: 30%;
  left: 50%;
  padding: 24px;
  max-width: 1290px;
  width: 95vw;
  transform: translateX(-50%);
  background-color: colors.$white;
  border-radius: 20px;
  z-index: 10;
  box-shadow: 0px 14px 42px colors.$black-alpha-10;

  & > *:not(:first-child) {
    margin-left: 22px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    text-align: start;
    flex: 1;

    a {
      font-weight: 500;
      text-decoration: none;
      color: colors.$primary-light;
    }
  }

  button {
    white-space: nowrap;
    margin-left: 22px;
  }

  @media screen and (min-width: map-get(vars.$breakpoints, 'medium')) {
    display: flex;
    padding: 48px;
    bottom: 50%;
  }

  img:last-child {
    position: absolute;
    top: 17px;
    right: 20px;
    cursor: pointer;
  }
}
